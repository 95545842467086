import '../../styles/imported/homepage.css.scss'

const Root = () => {
  return <>
    {/*Header*/}
    <nav className="navbar navbar-transparent" role="navigation">
      <div className="container">
        <div className="navbar-header">
          <a href="/">
          </a>
        </div>

        <div className="collapse navbar-collapse" id="navbar-collapse">
          <ul className="nav navbar-nav navbar-right">
          </ul>
        </div>
      </div>
    </nav>
    {/*End Header*/}


    <div className="index-features-header">
      <section className="index-app-hero" style={{height: '100vh'}}>
        <h1 className="stripey" style={{width: "296px", marginLeft: 'auto', marginRight: 'auto'}}>
          <img style={{height: '52px', width: '60px'}} src="/favicon.svg"/>
          {/* <Image*/}
          {/*  // size="mini"*/}
          {/*  // verticalAlign="middle"*/}
          {/*  src="/icon.svg"*/}
          {/*  alt="adronite-icon"*/}
          {/*  width={52}*/}
          {/*  height={60}*/}
          {/*/>*/}
          <div className='logo-text'>ADRONITE</div>
        </h1>
        <h1 className="stripey">
          Code clarity at your fingertips
        </h1>
        <p className="stripey description-subtext">
          Coming soon... Check back later for some exciting announcements!
        </p>
        <div className="cta">
          <a href="mailto:team@adronite.com?subject=Contact Adronite" className="btn-shadow btn-shadow-primary"
             style={{marginBottom: '0.5%'}}>
            Contact Us
          </a>
        </div>
      </section>
    </div>
  </>
}

export default Root