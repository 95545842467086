import logo from './logo.svg';
import './App.css';
import Routes from './routes';


const App = () => (
  <Routes/>
);

export default App;
