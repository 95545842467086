import { useRoutes } from 'react-router-dom';

import UnauthenticatedRoutes from './UnauthenticatedRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
      UnauthenticatedRoutes,
    ]);
}
