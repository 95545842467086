// import {lazy} from 'react';

// project import
// import Loadable from '../components/Loadable';
import MinimalLayout from '../layout/MinimalLayout';
// import Root from '../pages/unauthenticated/Root';
// import Root1 from '../pages/unauthenticated/Option1';
// import Root2 from '../pages/unauthenticated/Option2';
// import Original from '../pages/unauthenticated/Original';
import Stealth from '../pages/unauthenticated/Stealth';

// // render - login
// const AuthLogin = Loadable(lazy(() => import('../pages/authentication/Login')));
// const AuthRegister = Loadable(lazy(() => import('../pages/authentication/Register')));
// const Root = Loadable(lazy(() => import('../pages/unauthenticated/Root')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout/>,
  children: [
    {
      path: '/',
      element: <Stealth/>
    },
    // {
    //   path: '/option2',
    //   element: <Root2/>
    // },
    // {
    //   path: '/option1',
    //   element: <Root1/>
    // },
    // {
    //   path: '/original',
    //   element: <Original/>
    // }
  ]
};

export default LoginRoutes;
