import {Outlet} from 'react-router-dom';
import {useEffect} from 'react';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
    return <>
        <Outlet/>
    </>;
};

export default MinimalLayout;
